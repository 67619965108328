import React, { useState } from 'react';
import { Popover, Button, Input } from 'antd';

const PopOverEmailBtn = (props) => {
  const [popVisible, setPopVisible] = useState(false);
  const [mail, setMail] = useState('');
  const [validation, setValidation] = useState(false);
  const submit = (val) => {
    const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const valid = regex.test(val);

    if (valid) {
      props.Email(mail);
      setPopVisible(false);
      setMail('');
    } else {
      setValidation(true);
      setTimeout(() => {
        setValidation(false);
      }, 2000);
    }
  };
  const content = () => (
    <div>
      <Input onChange={(e) => setMail(e.target.value)} value={mail} />

      <div
        style={{
          color: 'red',
          fontWeight: 200,
          width: '100%',
          letterSpacing: 0.5,
        }}
      >
        {validation && 'Not a valid email'}
      </div>

      <Button
        onClick={() => {
          submit(mail);
        }}
        type="primary"
        style={{ marginTop: 10 }}
      >
        submit
      </Button>
      <Button
        onClick={() => {
          setPopVisible(false);

          setMail('');
        }}
        style={{ marginTop: 10, marginLeft: 20 }}
        danger
      >
        cancel
      </Button>
    </div>
  );

  return (
    <Popover
      placement="top"
      content={() => content()}
      title={`Please Enter Revceiver's Email Address`}
      trigger="click"
      visible={popVisible}
      onVisibleChange={() => setPopVisible(!popVisible)}
    >
      <Button
        onClick={() => setPopVisible(!popVisible)}
        style={{
          visibility: 'visible',
          paddingRight: 22,
          paddingLeft: 22,
          backgroundColor: 'grey',
        }}
        type={'primary'}
        danger={false}
      >
        {props.title}
      </Button>
    </Popover>
  );
};

export default PopOverEmailBtn;
